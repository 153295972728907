<script>
	// https://developers.google.com/web/updates/2017/02/media-session
	// https://googlechrome.github.io/samples/media-session/audio.html

	import { onMount } from 'svelte';
	import StationButton from '../components/StationButton.svelte';
	import PlayButton from '../components/PlayButton.svelte';
	import Snow from '../components/Snow.svelte';
	import Programme from '../components/Programme.svelte';

	import getRDS from '../rds';
	import { hasProgramme } from '../programme';
	import Newspaper from '../components/icons/Newspaper.svelte';

	function isChristmasTime() {
		const month = new Date().getMonth();
		const day = new Date().getDate();

		// If it's January or December, it's Christmas time :)
		// Or after 22th November
		if (month === 0 || month === 11 || (month === 10 && day > 22)) {
			return true;
		}

		return false;
	}

	const CHRISTMAS_STATIONS = [
		'rmfswieta',
		'rmfkoledy',
		'nagwiazdke',
		'nagwiazdkerock',
		'nagwiazdkejazz',
		'nagwiazdkehity',
		'nagwiazdkekoledy',
	];

	const list = {
		radiowroclaw: {
			title: 'Radio Wrocław',
			stream: 'https://stream4.nadaje.com:9241/prw',
			color: '#A01F2C',
		},
		radiokrakow: {
			title: 'Radio Kraków',
			stream: 'https://api.radiograj.pl/radiokrakow',
			// stream: "http://stream3.nadaje.com:9116/radiokrakow",
			color: '#002E5F',
		},
		rmfclassic: {
			title: 'RMF Classic',
			// stream: "https://rs202-krk-cyfronet.rmfstream.pl/rmf_classic",
			// Temporary premium stream (valid until 2023-12-20):
			stream: 'https://plus.rmfclassic.pl/plus/s/i/plus-premium?t=2427d5d857bb-40d98bbd5efc-82397c',
			//  'https://rs101-krk.rmfstream.pl/RMFCLASSIC48?aw_0_req.gdpr=true&aw_0_req.userConsentV2=CP1oQKeP1oQKeFKACAPLDdCsAP_AAEPAAB5Yg1NV_H__bW9r8X7_aft0eY1P9_j77sQxBhfJE-4F3LvW_JwXx2E5NF36tqoKmRoEu3ZBIUNlHJHUTVmwaogVryHsakWcpTNKJ6BkkFMRM2dYCF5vm4tjeQKY5_p_d3fx2D-t_dv839zzz8VHn3c5f-e0-PCdU5-9Dfn9fRfb-9IP9_78v8v8_l_rk2_eT13_pcvr_D--f_87_XW-9_AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEQagCTDQuIAuwICQm0DCKBACMKwgIoEAAAAJA0QEALgwKdgYBLrCRACBFAAcEAIQAUZAAgAAAgAQiACQIoEAAEAgEAAIAEAgEABAwACgAsBAIAAQHQMUwoAFAsIEiMiIUwIQoEggJbKhBKCoQVwgCLLACgERsFAAgAAEVgACAsXgMASAlQkECXUG0AABAAgFFKFQgk9MAA4JGy1B4ImgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAA.YAAAD4AAAAAA&aw_0_1st.playerid=RMF_Player_JS_P&aw_0_1st.rmf_disable_preroll=true',
			color: '#FFCC01',
		},
		radiopogoda: {
			title: 'Radio Pogoda',
			// stream: "http://stream.radioagora.pl/tuba139-1.mp3",
			// stream: 'https://pl-play.adtonos.com/radio-pogoda',
			stream: 'https://28013.live.streamtheworld.com/RADIO_POGODA.mp3',
			color: '#0F9D58',
		},
		rmfrock: {
			title: 'RMF Classic Rock',
			stream: 'https://rs101-krk-cyfronet.rmfstream.pl/CLASSICROCKF',
			color: '#F8AC00',
		},
		nowyswiat: {
			title: 'Nowy Świat',
			stream: 'https://stream.rcs.revma.com/ypqt40u0x1zuv',
			color: '#FF443B',
		},
		antyradio: {
			title: 'Antyradio',
			// stream: "https://n-15-5.dcs.redcdn.pl/sc/o2/Eurozet/live/antyradio.livx",
			stream: 'https://an04.cdn.eurozet.pl/ant-waw.mp3',
			color: '#D72027',
		},
		radio357: {
			title: 'Radio 357',
			stream: 'https://stream.open.fm/378?type=.aac',
			color: '#4f4f4f',
		},
		rmfswieta: {
			title: 'RMF Święta',
			stream:
				'https://rs203-krk-cyfronet.rmfstream.pl/SWIETAF?aw_0_req.gdpr=true&aw_0_1st.playerid=RMF_Player_JS_P',
			color: '#196805',
		},
		rmfkoledy: {
			title: 'RMF Kolędy',
			stream:
				'https://rs101-krk.rmfstream.pl/KOLEDY?aw_0_req.gdpr=true&aw_0_1st.playerid=RMF_Player_JS_P',
			color: '#366EAD',
		},
		nagwiazdke: {
			title: 'Na Gwiazdkę',
			stream: 'https://stream.open.fm/87?type=.aac',
			color: '#556B30',
		},
		nagwiazdkerock: {
			title: 'Rock Na Gwiazdkę',
			stream: 'https://stream.open.fm/91?type=.aac',
			color: '#313297',
		},
		nagwiazdkejazz: {
			title: 'Jazz & Classic Na Gwiazdkę',
			stream: 'https://stream.open.fm/88?type=.aac',
			color: '#F4732C',
		},
		nagwiazdkehity: {
			title: 'Hity Na Gwiazdkę',
			stream: 'https://stream.open.fm/90?type=.aac',
			color: '#F11E2B',
		},
		nagwiazdkekoledy: {
			title: 'Kolędy Na Gwiazdkę',
			stream: 'https://stream.open.fm/89?type=.aac',
			color: '#BD8450',
		},
		odglosyNatury: {
			title: 'Odgłosy Natury',
			stream: 'https://stream.open.fm/52?type=.aac',
			color: '#49AB59',
		},
		// trojka: {
		//   title: "Trójka",
		//   stream: "https://api.radiograj.pl/trojka.ogg",
		//   // stream: "http://w.dktr.pl:8000/trojka.ogg",
		//   color: "#E50177"
		// }
	};

	if (!isChristmasTime()) {
		CHRISTMAS_STATIONS.forEach((stationName) => {
			delete list[stationName];
		});
	}

	let stationName = 'radiowroclaw';
	let rdsFetcher = null;
	let rds = {};
	$: station = list[stationName];
	let playing = false;
	let loading = false;
	let showProgramme = false;
	let music = new Audio();
	$: stationHasProgramme = hasProgramme(stationName);

	onMount(() => {
		const lastStation = localStorage.getItem('lastStation');
		if (list[lastStation]) {
			stationName = lastStation;
		}
	});

	window.addEventListener('keydown', (e) => {
		if (e.keyCode === 32) {
			e.preventDefault();
			togglePlay();
		} else if (e.metaKey || e.ctrlKey) {
			return;
		} else if (event.keyCode >= 49 && event.keyCode <= 59) {
			const stationToSet = Object.keys(list)[event.key - 1];
			changeStation(stationToSet);
		}
	});

	music.addEventListener('pause', () => {
		playing = false;
		loading = false;
		clearRDS();
	});
	music.addEventListener('play', () => {
		playing = true;
		setMediaSession();
		fetchRDS();
		rdsFetcher = setInterval(fetchRDS, 30 * 1000);
	});

	music.addEventListener('loadstart', () => {
		loading = true;
	});
	music.addEventListener('canplay', () => {
		loading = false;
	});

	const changeStation = (station) => {
		stationName = station;
		localStorage.setItem('lastStation', station);
		clearRDS();
		closeProgramme();
		play();
	};

	const copyToClipboard = () => {
		if (rds.message) {
			navigator.clipboard.writeText(rds.message);
		}
	};

	const closeProgramme = () => {
		showProgramme = false;
	};

	const openProgramme = () => {
		showProgramme = true;
	};

	const fetchRDS = async () => {
		const data = await getRDS(stationName);
		if (data.message) {
			data.message = data.message.replace('&amp;', '&');
			data.message = data.message.slice(0, 80);
		}
		console.log('rds', stationName, data);
		rds = data;
		setMediaSession();
	};

	const clearRDS = () => {
		rds = {};
		if (rdsFetcher) {
			clearInterval(rdsFetcher);
		}
	};

	const togglePlay = () => {
		if (playing) {
			pause();
		} else {
			play();
		}
	};

	const play = async () => {
		console.log('PLAY');
		try {
			await music.pause();

			music.src = station.stream;
			music.play();
		} catch (e) {
			console.log('Play error', e);
		}
	};

	const pause = async () => {
		music.pause();
	};

	const setMediaSession = () => {
		if ('mediaSession' in navigator) {
			navigator.mediaSession.metadata = new MediaMetadata({
				title: (rds && rds.message) || station.title,
				artist: `Radiograj: ${station.title}`,
				artwork: [
					{
						src: `https://dummyimage.com/96x96/${station.color.replace(
							'#',
							''
						)}/FFFFFF.png&text=${encodeURI(station.title.replace('Radio ', ''))}`,
						sizes: '96x96',
						type: 'image/png',
					},
					{
						src: `https://dummyimage.com/128x128/${station.color.replace(
							'#',
							''
						)}/FFFFFF.png&text=${encodeURI(station.title.replace('Radio ', ''))}`,
						sizes: '128x128',
						type: 'image/png',
					},
					{
						src: `https://dummyimage.com/192x192/${station.color.replace(
							'#',
							''
						)}/FFFFFF.png&text=${encodeURI(station.title.replace('Radio ', ''))}`,
						sizes: '192x192',
						type: 'image/png',
					},
					{
						src: `https://dummyimage.com/256x256/${station.color.replace(
							'#',
							''
						)}/FFFFFF.png&text=${encodeURI(station.title.replace('Radio ', ''))}`,
						sizes: '256x256',
						type: 'image/png',
					},
					{
						src: `https://dummyimage.com/384x384/${station.color.replace(
							'#',
							''
						)}/FFFFFF.png&text=${encodeURI(station.title.replace('Radio ', ''))}`,
						sizes: '384x384',
						type: 'image/png',
					},
					{
						src: `https://dummyimage.com/512x512/${station.color.replace(
							'#',
							''
						)}/FFFFFF.png&text=${encodeURI(station.title.replace('Radio ', ''))}`,
						sizes: '512x512',
						type: 'image/png',
					},
				],
			});

			navigator.mediaSession.setActionHandler('play', play);
			navigator.mediaSession.setActionHandler('pause', pause);
		}
	};
</script>

<svelte:head>
	<meta name="theme-color" content={station.color} />
	<title>Radiograj | {station.title}</title>
	{#each Object.keys(list).slice(0, 8) as station}
		<link rel="preconnect" href={list[station].stream} as="audio" />
	{/each}
</svelte:head>

<div class="container">
	{#if stationHasProgramme}
		<Programme
			station={stationName}
			isOpen={showProgramme}
			open={openProgramme}
			close={closeProgramme}
		/>
	{/if}

	<section
		class="headline"
		style="background-color: {station.color}; background:
    linear-gradient(-45deg, {station.color}, {station.color}); background-size:
    400% 400%; animation: gradient 15s ease infinite; "
	>
		{#if stationHasProgramme}
			<button
				class="programme__button"
				on:click={() => {
					showProgramme = true;
				}}><Newspaper /> <span class="programme__button-title">Ramówka</span></button
			>
		{/if}

		{#if CHRISTMAS_STATIONS.includes(stationName)}
			<Snow />
		{/if}
		<!-- <a class="secretLink" href="/#/dom">S</a> -->

		<h1 class="title">{station.title}</h1>
		{#if rds.message}
			<div class="rds__text">
				{rds.message}
				<br />
				<div class="rds__buttons">
					<a
						href={`https://www.youtube.com/results?search_query=${encodeURIComponent(rds.message)}`}
						class="rds__copy"
						target="_blank"
						rel="noopener noreferrer"
						title="Otwórz na Youtube"
					>
						🔗 &nbsp;YouTube
					</a>
					<button class="rds__copy" on:click={copyToClipboard} title="Skopiuj tytuł">
						📋 &nbsp;Kopiuj
					</button>
				</div>
			</div>
		{/if}
	</section>

	<!-- <section> -->
	<div class="stations">
		{#each Object.keys(list) as station}
			<StationButton {...list[station]} playStation={changeStation} id={station} />
		{/each}
	</div>
	<!-- </section> -->

	<section class="controls">
		<PlayButton {togglePlay} {loading} {playing} christmas={isChristmasTime()} />
	</section>
</div>

<!-- <audio src="https://radiograj.netlify.com/api/radiowroclaw" autoplay controls /> -->

<!-- <audio bind:this={music} src={station.stream} crossOrigin="anonymous" /> -->
<style>
	.container {
		display: grid;
		height: 100%;
		grid-template-rows: 150px 1fr auto;
		grid-template-areas:
			'header'
			'stations'
			'control';
	}

	.headline {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		color: #fff;
		grid-area: header;
		/* background-position: center 1rem;
    background-size: 50px;
    background-repeat: no-repeat; */
	}

	@media (prefers-color-scheme: dark) {
		.headline {
			position: relative;
			color: #000;
		}

		.headline:before {
			position: absolute;
			display: block;
			content: '';
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;

			background-color: rgba(0, 0, 0, 0.3);
		}
	}

	.title {
		margin: 0;
		padding: 0.5rem;
		font-size: 2.3rem;
		text-align: center;
	}

	@media (max-width: 400px) {
		.title {
			font-size: 1.8rem;
		}
	}

	.rds__text {
		/* position: absolute; */
		/* bottom: 1rem; */
		text-align: center;
	}

	.rds__buttons {
		display: flex;
		justify-content: center;
		margin-top: 0.3rem;
	}

	.rds__copy {
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 2rem;
		/* height: rem; */
		margin: 0.5rem;
		padding: 0.4rem;
		border: none;
		background: transparent;
		text-align: center;
		border-radius: 0.3rem;
		cursor: pointer;
		color: rgba(255, 255, 255, 0.6);
		text-decoration: none;
		text-transform: uppercase;
		font-size: 0.8rem;
		background-color: rgba(255, 255, 255, 0.1);
	}

	.rds__copy:hover {
		background-color: rgba(255, 255, 255, 0.5);
		color: rgba(255, 255, 255, 1);
	}

	@media (prefers-color-scheme: dark) {
		.rds__copy {
			filter: invert(1);
		}
	}

	@media (max-height: 590px) {
		.rds__text {
			position: relative;
			font-size: 0.9rem;
		}
	}

	.stations {
		grid-area: stations;
		display: flex;
		/* align-self: flex-end; */
		flex-wrap: wrap;
		margin: 5px;
		/* overflow-y: scroll; */
		overflow-y: auto;
	}

	.controls {
		grid-area: control;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.secretLink {
		position: absolute;
		top: 0rem;
		left: 2rem;
		padding: 1rem 1rem;
		color: #fff;
		opacity: 0.2;
	}

	@media (min-width: 1000px) {
		.container {
			grid-template-rows: 1fr;
			grid-template-columns: 1fr 1fr;
			grid-template-areas:
        /* "header stations" */ 'header stations';
		}

		.rds__text {
			position: absolute;
			bottom: 30%;
		}

		.stations {
			overflow-y: auto;
			/* max-width: 650px; */
			margin: 2rem 2rem;
			justify-content: flex-between;
			/* display: grid;
      grid-template-columns: 1fr 1fr 1fr; */
			justify-content: center;
			/* grid-auto-rows: 100px; */
		}

		.controls {
			position: absolute;
			bottom: 1rem;
			left: 0;
			width: 50%;
		}
	}

	.programme__button {
		position: absolute;
		display: flex;
		align-items: center;
		top: 2rem;
		margin-inline: auto;
		padding: 1rem;
		background: transparent;
		font-weight: bold;
		text-transform: uppercase;
		color: #000;
		border: none;
		border-radius: 0.5rem;
		cursor: pointer;
		gap: 0.5rem;
	}

	.programme__button :global(svg) {
		width: 30px;
		height: 30px;
	}

	.programme__button:hover {
		background: rgba(255, 255, 255, 0.5);
	}

	@media (max-width: 1000px) {
		.programme__button {
			top: 0rem;
			left: unset;
			padding: 0 2rem;
			flex-direction: column;
			right: 0rem;
			padding: 0.5rem 0.5rem;
			font-size: 0.6rem;
			gap: 0;
			letter-spacing: 0.5px;
		}
	}
</style>
