<script>
  export let id = ""
  export let title = ""
  export let color = "#444"
  // export let logo = ""
  export let playStation = () => {}
</script>

<button on:click={() => playStation(id)} style="background-color: {color};">
  <div class="content">{title}</div>
</button>

<style>
  button {
    display: block;
    /* align-items: center; */
    /* justify-content: center; */
    position: relative;
    width: 49vw;
    flex-basis: calc(33.333% - 10px);
    margin: 5px;
    padding: 0;
    position: relative;
    border: none;
    border-radius: 20px;
    background-color: #aaa;
    cursor: pointer;
  }

  button:focus {
    /* outline: none; */
  }

  button::before {
    content: "";
    display: block;
    padding-top: 90%;
  }

  button:after {
    content: "";
    display: block;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;

    font-weight: bold;
    font-size: 1.1rem;
    text-shadow: 0.5px 0.5px 2px rgba(0, 0, 0, 0.3), -0.5px -0.5px 2px rgba(0, 0, 0, 0.3);
    color: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .content {
      text-shadow: none;
      color: #000;
    }
  }

  @media (max-width: 330px) {
    button {
      /* flex-basis: calc(50% - 10px); */
    }
    button::before {
      padding-top: 90%;
    }
    .content {
      font-size: 1.1rem;
    }
  }

  @media (min-width: 1000px) {
    button {
      /* flex-basis: calc(25% - 10px); */
      /* flex-basis: unset; */
      /* width: calc(25% - 10px); */
      /* max-width: 200px; */
    }
    button::before {
      padding-top: 50%;
    }

    .content {
      font-size: 1.5rem;
    }
  }
</style>
