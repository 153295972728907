<script>
  export let playing
  export let loading
  export let christmas
  export let togglePlay = () => {}
</script>

<button class={`${playing && "playing"} ${loading && "loading"}`} on:click={togglePlay}>
  <div class="content">
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve">
      {#if playing}
        <path d="M162.642 148.337h56.034v215.317h-56.034v-215.316z" fill="#000000" />
        <path d="M293.356 148.337h56.002v215.317h-56.002v-215.316z" fill="#000000" />
      {:else}
        <path d="M156.4,102.9L421.6,256L156.4,409.1L156.4,102.9z" />
      {/if}
    </svg>

    {#if christmas}
      <img class="santaHat" src="/hat.png" alt="" loading="lazy" />
    {/if}
  </div>
</button>

<style>
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: 1rem;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }

  button:focus {
    outline: none;
  }

  .santaHat {
    display: block;
    position: absolute;
    top: -15px;
    right: -25px;
    width: 50px;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0;
    width: 250px;
    height: 90px;
    border: none;
    border-radius: 15px;
    background-color: #ddd;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.6); */
    font-weight: bold;
    font-size: 1.6rem;
    /* z-index: 1; */
  }

  @media (prefers-color-scheme: dark) {
    .content {
      background-color: #888;
    }
  }

  @media (max-height: 590px) {
    .content {
      height: 65px;
    }
  }

  .content svg {
    display: block;
    width: 50px;
    height: 50px;
  }

  @keyframes zoomInOut {
    0%,
    100% {
      transform: scaleX(0.8) scaleY(0.8);
    }
    50% {
      transform: scaleX(1.1) scaleY(1.2);
    }
  }

  @keyframes colors {
    0%,
    24% {
      background: green;
    }
    25%,
    49% {
      background: red;
    }
    50%,
    74% {
      background: yellow;
    }
    75%,
    99% {
      background: blue;
    }
    100% {
      background: orange;
    }
  }

  .loading .content:before {
    display: block;
    position: absolute;
    content: "";
    border-radius: 30px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: red;
    z-index: -1;
    animation: zoomInOut 2s infinite ease-in-out, colors 8s infinite;
    filter: blur(5px);
    opacity: 0.5;
  }
</style>
