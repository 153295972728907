<script>
	import { onMount } from 'svelte';

	const url = 'http://192.168.1.105';
	const channels = [
		'Nowy Świat',
		'Radio Wrocław',
		'Radio Pogoda',
		'RMF Classic',
		'Radio 357',
		'RMF Muzyka Filmowa',
	];

	async function volUp() {
		await fetch(url + '/php/doVol.php?VL=1');
	}

	async function volDown() {
		await fetch(url + '/php/doVol.php?VL=-1');
	}

	async function mute() {
		await fetch(url + '/php/doVol.php?VL=128');
	}

	async function unmute() {
		await fetch(url + '/php/doVol.php?VL=0');
	}

	async function playChannel(channelNumber) {
		await fetch(url + '/doApi.cgi?AI=16&CI=' + channelNumber);
	}

	const keyboardActions = {
		'-': volDown,
		'+': volUp,
		0: mute,
		9: unmute,
		1: () => playChannel(1),
		2: () => playChannel(2),
		3: () => playChannel(3),
		4: () => playChannel(4),
		5: () => playChannel(5),
		6: () => playChannel(6),
	};

	function handleKeyPress(event) {
		console.log(event.key);
		if (keyboardActions[event.key]) {
			keyboardActions[event.key]();
		}
	}

	onMount(() => {
		window.addEventListener('keypress', handleKeyPress);

		return () => {
			window.removeEventListener('keypress', handleKeyPress);
		};
	});
</script>

<section class="container">
	<div class="grid">
		<button on:click={volDown} data-key="–">Ciszej ⬇️</button>
		<button on:click={volUp} data-key="+">Głośniej ⬆️</button>
		<button on:click={mute} data-key="0">Wycisz 😶</button>
		<button on:click={unmute} data-key="9">Włącz dźwięk 📣</button>
	</div>

	<div class="grid">
		{#each channels as channel, index}
			<button on:click={() => playChannel(index)} data-key={index}>{channel}</button>
		{/each}
		<a href="/">Radiograj 🚪</a>
	</div>
</section>

<style>
	.container {
		max-width: 600px;
		margin-inline: auto;
	}
	.grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 4rem;
		gap: 1rem;
		margin: 3rem 1rem;
	}

	button,
	a {
		position: relative;
		font-size: 1.2rem;
		padding: 0.2rem;
		border: none;
		border-radius: 0.5rem;
		background: #efefef;
		display: grid;
		align-content: center;
		text-align: center;
		color: #000;
		text-decoration: none;
		cursor: pointer;
	}

	@media (min-width: 600px) {
		button:after {
			position: absolute;
			top: 0.2rem;
			right: 0.3rem;
			content: attr(data-key);
			color: gray;
			font-size: 1rem;
			font-family: monospace;
		}
	}
</style>
