<script>
	import { onMount } from 'svelte';
	import getProgramme from '../programme';
	import X from './icons/X.svelte';

	export let isOpen;
	export let station;
	export let close = () => {};
	export let open = () => {};

	console.log(station);
	$: programme = [];

	$: if (station) {
		getProgramme(station).then((data) => {
			if (Array.isArray(data)) {
				programme = data;
			}
		});
	}

	const handleKeydownEvent = (e) => {
		console.log(e);
		if (e.key === 'Escape') {
			dialog.close();
		}

		// Ignore key combinations
		if (e.metaKey || e.ctrlKey) return;

		if (e.key === 'r') {
			if (isOpen) {
				close();
			} else {
				open();
			}
		}
	};

	onMount(() => {
		const dialog = document.querySelector('dialog');
		dialog.addEventListener('close', () => {
			close();
		});

		// Close on esc press
		document.addEventListener('keydown', handleKeydownEvent);

		return () => {
			dialog.removeEventListener('close', () => {
				close();
			});

			document.removeEventListener('keydown', handleKeydownEvent);
		};
	});
</script>

{#if isOpen}
	<button on:click={close} class="background"></button>
{/if}

<dialog class="programme__container" open={isOpen}>
	<button on:click={close} class="programme__button"><X /></button>

	<div class="programme__inner">
		{#each programme as { title, time, hosts }}
			<div class="card">
				<div class="card__time">{time}</div>
				<h3 class="card__title">{title}</h3>
				<div class="card__description">{@html hosts}</div>
			</div>
		{/each}
	</div>
</dialog>

<style>
	.background {
		position: fixed;
		inset: 0rem;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 9;
		border: none;
	}

	.programme__container {
		position: fixed;
		inset: 0rem;
		width: 80vw;
		max-width: 1200px;
		height: 80vh;
		overflow-y: auto;
		background-color: #111;
		color: #fff;
		z-index: 10;
	}

	.programme__inner {
		margin-top: -4rem;
		padding: 1rem;
		columns: 2;
		column-gap: 4rem;
	}

	.programme__button {
		position: sticky;
		top: 0;
		left: 100%;
		right: 0rem;
		width: 3.5rem;
		height: 3.5rem;
		/* padding: 1rem 1.5rem; */
		background-color: transparent;
		border: none;
		cursor: pointer;
	}

	.programme__button :global(svg) {
		width: 2.5rem;
		height: 2.5rem;
		fill: #fff;
	}

	.programme__button:hover {
		background-color: rgba(255, 255, 255, 0.1);
	}

	.card {
		display: grid;
		grid-template-columns: 80px 1fr;
		grid-template-rows: auto 1fr;
		break-inside: avoid-column;
		/* background-color: #111; */
		/* border: 1px solid #DDD; */
		/* color: #fff; */
		padding: 1rem 0;
		/* margin: 1rem; */
		gap: 1rem;
	}

	.card__title {
		margin: 0;
		font-size: 1.5rem;
	}

	.card__time {
		grid-column: 1 / 2;
		grid-row: 1 / 3;
		padding-right: 1rem;
		font-size: 1.5rem;
		font-weight: bold;
		text-align: center;
		border-right: 1px solid #ddd;
	}

	.card__description {
		font-size: 0.9rem;
		line-height: 1.3em;
		letter-spacing: 0.9px;
		color: #eee;
	}

	.card__description :global(a) {
		color: #fff;
	}

	@media (max-width: 900px) {
		.programme__container {
			width: 90vw;
		}

		.programme__inner {
			columns: 1;
		}
	}

	@media (max-width: 500px) {
		.programme__container {
			height: 98vh;
		}

		.programme__inner {
			margin-top: -4.5rem;
			padding: 1rem 0;
		}

		.card {
			grid-template-columns: 70px 1fr;
		}

		.card__description {
			font-size: 0.8rem;
		}

		.card__title {
			font-size: 1.3rem;
		}

		.card__time {
			font-size: 1.2rem;
		}
	}
</style>
